import { autoinject, bindable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { PubSub } from 'lib/event/PubSub';
import { AsyncErrorHandler, ComponentLoadingFader } from 'lib/ui';
import { MooringBridle, MooringComponentType, UpdateMooringBridleDto } from 'models/mooring';
import { ToastService } from 'services';
import { MooringService } from 'services/mooring';

@autoinject
export class BridlesContainer {
  @bindable
  protected mooringId: number;

  @bindable
  protected reviewId?: number;

  protected bridles: MooringBridle[] = [];

  constructor(
    protected element: Element,
    private mooringService: MooringService,
    private toaster: ToastService,
    private t: I18N,
    private pubsub: PubSub
  ) {
    pubsub.sub('entity:updated', async () => {
      void this.getBridles();
    });
    pubsub.sub('list-entity:updated', async () => {
      void this.getBridles();
    });
  }

  @AsyncErrorHandler
  @ComponentLoadingFader
  async attached() {
    if (!this.mooringId) {
      throw new Error('mooringId is required => mooring-id.bind="<id>"');
    }

    await this.getBridles();
  }

  @AsyncErrorHandler
  protected async getBridles() {
    const { data } = await this.mooringService.getBridles(this.mooringId);
    const lines = data;
    if (lines.length) {
      for (const line of lines) {
        line.Components?.sort((a, b) => a.SortIndex - b.SortIndex);
      }
      setTimeout(() => {
        this.bridles = lines;
      }, 10);
    }
  }

  @AsyncErrorHandler
  protected async onSaveBridles({ entries, canNavigateOnSave }: { entries: MooringBridle[]; canNavigateOnSave: boolean }) {
    const dto = entries.map(
      (line) =>
        ({
          Id: line.Id,
          BuoyId: line.BuoyId,
          BuoyNo: line.BuoyNo,
          CageId: line.CageId,
          CageNo: line.CageNo,
          DimForce: line.DimForce,
          MoorlogNo: line.MoorlogNo,
          IsAccident: line.IsAccident,
          AnalysisNo: line.AnalysisNo,
          MinMblAnchoring: line.MinMblAnchoring,
          MinMblRopesStraps: line.MinMblRopesStraps,
          MinMblChainShackleConnectionPlates: line.MinMblChainShackleConnectionPlates,
          //
          Components: line.Components,
        }) satisfies UpdateMooringBridleDto
    );

    await this.mooringService.updateBridles(this.mooringId, dto);
    this.toaster.showUpdated();
    this.pubsub.publish('list-entity:updated', { name: MooringComponentType.bridle });
    if (canNavigateOnSave) {
      this.pubsub.publish('tabs:change-guard-ok', null);
    } else {
      await this.getBridles();
    }
  }

  protected unbind() {
    this.pubsub.unsub();
  }

  protected detached() {
    this.pubsub.unsub();
  }
}
