import { autoinject, bindable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { ListField } from 'models/ListSettings';
import { CommentForEntity, MooringBridle, MooringComponent, MooringComponentType } from 'models/mooring';
import { MooringArticleService, ToastService } from 'services';
import { MooringComponentSearchHandler, MooringService } from 'services/mooring';
import { toKg, toChecked } from 'lib/ui/ValueDisplayFormatters';
import { MooringTableBase } from '../table-base';
import { TableEntryManager } from 'services/ui-helpers';
import { DialogService } from 'aurelia-dialog';
import { PubSub } from 'lib/event/PubSub';
import { ConfirmDialog } from 'components/dialogs/confirm/confirm-dialog';
import { AsyncErrorHandler } from 'lib/ui';
import { TableManager } from '../TableManager';

@autoinject
export class BridlesTable extends MooringTableBase<MooringBridle> {
  protected table = new TableManager('bridles-table', tableColumns);

  @bindable
  protected onSave: (args: { entries: MooringBridle[]; canNavigateOnSave: boolean }) => void;

  constructor(
    protected t: I18N,
    protected toast: ToastService,
    dialogService: DialogService,
    mooringArticleService: MooringArticleService,
    // Used in HTML for article search
    mooringComponentSearchHandler: MooringComponentSearchHandler,
    tableEntryManager: TableEntryManager,
    pubsub: PubSub,
    confirmation: ConfirmDialog,
    mooringService: MooringService
  ) {
    super(
      'bridle',
      dialogService,
      mooringComponentSearchHandler,
      tableEntryManager,
      mooringArticleService,
      pubsub,
      confirmation,
      mooringService
    );

    pubsub.sub('list-entity:updated', (data) => {
      if (!data) return;
      if (data.name === 'mooring-article-component') {
        this.onSave({
          entries: this.entries,
          canNavigateOnSave: false,
        });
      }
    });

    pubsub.sub('tabs:change-guard', async () => {
      if (this.changeTracker.areEqual(this.entries)) {
        this.pubsub.publish('tabs:change-guard-ok', null);
        return;
      }

      const res = await this.confirmation.confirmSave();

      if (res == 'cancel') {
        return;
      } else if (res === 'discard-continue') {
        this.pubsub.publish('tabs:change-guard-ok', null);
      } else {
        this.onSave({ entries: this.entries, canNavigateOnSave: true });
      }
    });
  }

  async attached() {
    await super.attached();
  }

  protected onElementSelected(type: MooringComponentType, item: { id: number; title: string }, line: MooringBridle, entry: keyof MooringBridle) {
    if (type == 'buoy' && entry === 'BuoyId') {
      line.BuoyId = item.id;
      line.BuoyNo = item.title;
    }

    if (type == 'cage' && entry === 'CageId') {
      line.CageId = item.id;
      line.CageNo = item.title;
    }
  }

  protected sortTable() {
    console.log('sortTable');
  }

  protected onAddComponent() {
    this.entries.push({
      MoorlogNo: `X`,
    } as MooringBridle);
  }

  @AsyncErrorHandler
  protected async onDelete(index: number) {
    const res = await this.confirmation.confirmDelete();
    if (!res) return;

    const entry = this.entries[index];
    if (!entry) return;

    if (entry.Id) {
      await this.mooringService.deleteBridle(this.mooringId, entry.Id);
    }

    this.entries.splice(index, 1);
    this.toast.showDeleted();
  }

  protected save() {
    this.onSave({
      entries: this.entries,
      canNavigateOnSave: false,
    });
  }

  protected addEntryComment(component: MooringBridle) {
    this.openCommentDialog({
      mooringId: this.mooringId,
      isReadOnly: false,
      componentId: component.Id,
      componentType: CommentForEntity.Bridle,
    });
  }

  protected addComponentComment(component: MooringComponent) {
    this.openCommentDialog({
      mooringId: this.mooringId,
      isReadOnly: false,
      componentId: component.Id,
      componentType: CommentForEntity.BridleComponent,
    });
  }

  protected unbind() {
    this.pubsub.unsub();
    super.unbind();
  }

  protected detached() {
    this.pubsub.unsub();
  }
}

const tableColumns: ListField[] = [
  {
    field: 'MoorlogNo',
    title: 'mooring.bridleNo',
    selected: true,
    visible: true,
    filterType: 'TEXT',
    transform: (value: string) => value,
    inputType: 'text',
    disableControl: true,
  },
  {
    field: 'AnalysisNo',
    title: 'mooring.analysisNo',
    selected: true,
    visible: true,
    filterType: 'TEXT',
    transform: (value: string) => value,
    inputType: 'text',
  },
  {
    field: 'BuoyNo',
    title: 'mooring.buoy',
    selected: true,
    visible: true,
    filterType: 'TEXT',
  },
  {
    field: 'CageNo',
    title: 'mooring.cage',
    selected: true,
    visible: true,
    filterType: 'TEXT',
  },
  {
    field: 'DimForce',
    title: 'mooring.dimForce',
    selected: true,
    visible: true,
    filterType: 'TEXT',
    transform: toKg,
    inputType: 'float',
  },
  {
    field: 'IsAccident',
    title: 'mooring.isAccident',
    selected: true,
    visible: true,
    filterType: 'BOOL',
    transform: toChecked,
    inputType: 'bool',
  },
  {
    field: 'MinMblRopesStraps',
    title: 'mooring.minMblRope',
    selected: true,
    visible: true,
    filterType: 'TEXT',
    transform: toKg,
    inputType: 'float',
  },
  {
    field: 'MinMblChainShackleConnectionPlates',
    title: 'mooring.minMblChain',
    selected: true,
    visible: true,
    filterType: 'TEXT',
    transform: toKg,
    inputType: 'float',
  },
  {
    field: 'MinMblAnchoring',
    title: 'mooring.minMblAnchoring',
    selected: true,
    visible: true,
    filterType: 'TEXT',
    transform: toKg,
    inputType: 'float',
  },
  {
    field: 'actions',
    title: '',
    selected: true,
    visible: true,
    filterType: 'none',
  },
];
